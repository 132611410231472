<template>
  <div>
    <a-alert
      v-if="!isRapidSmart && !isExhaustive"
      class="mb-20"
      :message="$t('components.notifications.note')"
      :description="$t('components.notifications.templateDependOnAgeGroup')"
      type="info" show-icon/>
    <div class="calendar-actions">
      <a-button
        type="link"
        class="txt-18 txt-black txt-font-din-medium mr-20 flex align-center"
        data-cy="download-local-event-calendar-button"
        :href="downloadUrl"
        target="blank">
        <embed width="30px" src="/icon-cloud-download.svg" class="mr-10">
        {{ $t('components.description.downloadLocalEventCalendar') }}
      </a-button>
      <a-button v-if="metadata && metadata.geographicCountry"
        data-cy="find-previously-created-calendar-button"
        type="link" class="flex align-center txt-18 txt-black txt-font-din-medium"
        @click="findCalendarModalActive = true">
        <embed width="25px" src="/icon-magnifying-glass.svg" class="mr-10">
        {{ $t('components.description.findPreviouslyCreatedCalendar') }}
      </a-button>
    </div>
    <FindCalendarModal v-if="findCalendarModalActive" :on-exit="onExit" :metadata="metadata"/>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'CalendarActions',
  components: {
    FindCalendarModal: () => import('./FindCalendarModal/find-calendar-modal.vue')
  },
  data() {
    return {
      findCalendarModalActive: false
    };
  },
  computed: {
    ...mapGetters(['isRapidSmart', 'isExhaustive']),
    ...mapState({
      metadata: state => state.survey.metadata
    }),
    downloadUrl() {
      if (this.metadata && this.metadata.includedAgeGroup === '0-59') {
        return '/Local Events Calendar Template_0-59.docx';
      }
      return '/Local Events Calendar Template_6-59.docx';
    }
  },
  methods: {
    onExit() {
      this.findCalendarModalActive = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.calendar-actions {
  display: flex;
  justify-content: center;
  margin: 10px;
}
</style>
